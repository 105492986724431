/* ==================== */
/* Variables */
/* ==================== */
/* ==================== */
/* Base */
/* ==================== */
body {
  background-color: #fff;
}

main {
  width: 100%;
}

/* ==================== */
/* Ant Design */
/* ==================== */
#layout-main {
  background-color: #f5f5f5;
}

.ant-layout-content {
  background-color: #f5f5f5;
}

.anticon {
  vertical-align: 0 !important;
}

// Button
button {
  .anticon {
    vertical-align: 0.125em !important;
  }

  &.ant-btn {
    &:hover {
      z-index: 1;
    }
  }
}

.ant-menu {
  border-right: none !important;
}

// Card
.ant-card {
  overflow: hidden;
}

// Select
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: 0;
}

.ant-select-item-option-content {
  white-space: normal !important;
  text-overflow: inherit !important;
}

// Modal
.ant-modal-content {
  border-radius: 12px !important;

  .ant-modal-header {
    border-radius: 12px 12px 0 0;
  }

  .ant-modal-footer {
    border-radius: 0 0 12px 12px;
  }

  .ant-modal-body {
    padding: 24px 48px;
  }
}

.modal-small {
  .ant-modal-content {
    border-radius: 0 !important;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    padding: 2rem !important;
  }
}

/* ==================== */
/* Memex Custom */
/* ==================== */
.memex-ico-user {
  background-image: url(../assets/images/ico-user.svg);
  background-position: center;
  background-repeat: no-repeat;
}

.memex-logo {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &.horizontal {
    background-image: url(../assets/images/logo-hor.svg);
  }

  &.vertical {
    background-image: url(../assets/images/logo-ver.svg);
  }

  &.mono {
    &.horizontal {
      background-image: url(../assets/images/logo-mono-hor.svg);
    }

    &.vertical {
      background-image: url(../assets/images/logo-mono-ver.svg);
    }
  }
}

.ant-layout {
  // min-width: 1000px;
  overflow-x: auto;
  background-color: transparent !important;
}

.hover-trigger .hover-target {
  display: none;

  @media (hover: none) {
    display: block;
  }
}

.hover-trigger:hover .hover-target {
  display: block;
}

.text-xxs {
  font-size: 0.6875rem;
}

.text-xxxs {
  font-size: 0.625rem;
}

.cell-list {
  .cell-item {
    width: 100%;
    background-color: #fff;

    ::-webkit-scrollbar {
      display: none;
    }

    .codex-editor__loader {
      position: relative;
      height: 32px;
    }

    .codex-editor__loader:before {
      width: 1rem;
      height: 1rem;
      margin-top: -0.75rem;
      margin-left: -0.5rem;
    }
  }

  &:hover {
    .cell-item {
      background-color: #f0f0f0 !important;

      .cell-component {
        &.cell-component-warning {
          background-color: rgb(255, 217, 212, 0.3);
        }
      }
    }
  }

  &.cell-warning {
    .cell-item {
      background-color: rgb(255, 240, 238, 0.9);
    }
  }

  &.cell-list-checked {
    .cell-item {
      background-color: #b4efc2;
    }
  }
}

.cell-list {
  padding-right: 5rem;
}

.cell-component {
  &.cell-component-warning {
    background-color: rgb(255, 217, 212, 0.65);
  }
}

.ant-editor-input {
  border-radius: 0 !important;
}

.cell-active {
  box-shadow: 0px 0px 0px 1px black inset;

  .ant-editor-input {
    box-shadow: 0px 0px 0px 1px black inset !important;
    // border-color: transparent !important;
    border: none !important;
  }
}

#contents-list {
  &::-webkit-scrollbar {
    display: none;
  }
}

.header-cell {
  transition: none;
}

#contents-fake-list,
#contents-body {
  &::-webkit-scrollbar {
    width: 1rem;
  }

  &::-webkit-scrollbar-button {
  }

  &::-webkit-scrollbar-track {
    background-color: #f8f8f8;
  }

  &::-webkit-scrollbar-track-piece {
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }

  &::-webkit-scrollbar-corner {
  }
}

// Split
.split {
  display: flex;
  flex-direction: row;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}

// Lazyload
.lazyload-wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    display: block;
    border-radius: 0 !important;
  }
}

// Editor
.codex-editor__redactor {
  padding-bottom: 0 !important;
  min-height: 200px !important;
}

.ce-block__content {
  .ce-header {
    padding: 0.4rem 0 !important;
  }

  h1 {
    display: block;
    font-size: 2rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h4 {
    display: block;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h5 {
    display: block;
    font-size: 0.83rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h6 {
    display: block;
    font-size: 0.67rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
}

.ce-paragraph {
  code {
  }
}

// Messsage
.ant-message {
  z-index: 9999 !important;
}

// Progress
.ant-progress-circle .ant-progress-text {
  font-size: 0.75rem !important;
}
